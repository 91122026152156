import VueRouter from 'vue-router';
import routes from './menu';
import Vue from 'vue';

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

router.beforeEach((to, meta, next) => {
  if (to.meta.nologin) {
    next();
  } else if (!window.sessionStorage.getItem('token')) {
    next('/login');
  } else {
    next();
  }
})

export default router