export default [
	{
		path: '/',
		redirect: '/index'
	},
	{
		name: '首页',
    path: '/index',
		component: (resolve) => require(['../views/index.vue'], resolve)
	},
	{
		name: '登录',
		path: '/login',
		component: (resolve) => require(['../views/login.vue'], resolve),
		meta: {
	    nologin: true
	  }
	}
];